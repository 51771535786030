<template>
  <v-select
    ref="vSelectTimeZones"
    v-model="selectedTimezone"
    appendToBody
    class="w-full"
    :class="{'border-danger': borderDanger}"
    label="name"
    :multiple="false"
    :closeOnSelect="true"
    :options="timezonesWithNames"
    :clearable="clearable"
    :filterBy="filterBy"
    @search:focus="onFocus()">
    <template #option="{ timezone, offset }">
      <span :title="timezone">({{ gmtText(offset) }}) {{ $t(`$TimeZones.${timezone}`) }}</span>
    </template>
    <template #selected-option="{ timezone, offset }">
      <span :title="timezone">({{ gmtText(offset) }}) {{ $t(`$TimeZones.${timezone}`) }}</span>
    </template>
  </v-select>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';
import vSelect from 'vue-select';
import timezones from '@/assets/utils/timezones';

/**
 * Vue select for timezones
 *
 * @module views/modules/components/TimezoneSelect
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} [borderDanger=false] - indicate if show border danger or no
 * @vue-prop {boolean} [clearable=true] - indicate if select is clearable or no
 * @vue-prop {string} value - component value
 * @vue-prop {boolean} [fixZIndex=true] -
 * indicate if fix z index of select drop down list (i.e for modals)
 * @vue-data {Object[]} [timezones=[...]] - available timezones
 * @vue-data {Object} [selectedTimezone=null] - selected timezone on component
 * @vue-event {void} setSelectedTimezone - set the selected timezone
 * @vue-event {void} onFocus - call on select focus
 */
export default {
  name: 'TimezoneSelect',
  components: {
    vSelect,
  },
  props: {
    borderDanger: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      required: true,
    },
    fixZIndex: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timezones,
      selectedTimezone: null,
    };
  },
  computed: {
    timezonesWithNames() {
      return this.timezones.map((tz) => ({ ...tz, name: this.$t(`$TimeZones.${tz.timezone}`) }));
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.setSelectedTimezone(val);
      } else {
        this.selectedTimezone = null;
      }
    },
    selectedTimezone(val) {
      this.$emit('input', val.timezone);
    },
  },
  created() {
    this.setSelectedTimezone(this.value || moment.tz.guess());
  },
  methods: {
    gmtText(offset) {
      const intPart = Math.trunc(offset);
      let decPart = (offset % 1);
      decPart = decPart < 0 ? decPart * -1 : decPart;
      let gmt = `GMT${offset >= 0 ? '+' : ''}`;
      const format = new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 2,
        minimumFractionDigits: 0,
      });

      gmt += `${format.format(intPart)}:`;

      if (decPart > 0 && decPart < 0.5) {
        gmt += `${format.format(15)}`;
      } else if (decPart === 0.5) {
        gmt += `${format.format(30)}`;
      } else if (decPart > 0.5 && decPart <= 0.9) {
        gmt += `${format.format(45)}`;
      } else {
        gmt += `${format.format(0)}`;
      }

      return gmt;
    },
    setSelectedTimezone(timezoneStr) {
      const timezone = timezones.find((tz) => tz.timezone === timezoneStr);

      if (timezone) {
        this.selectedTimezone = {
          ...timezone,
          name: this.$t(`$TimeZones.${timezone.timezone}`),
        };
      }
    },
    onFocus() {
      if (this.fixZIndex) {
        setTimeout(() => {
          const $dropDown = this.$refs.vSelectTimeZones.$el.querySelector('.vs__dropdown-toggle');
          const dropdownListId = $dropDown.getAttribute('aria-owns');
          const $dropdownList = document.querySelector(`#${dropdownListId}`);
          $dropdownList.style.zIndex = '99999';
        });
      }
    },
    filterBy(option, label, search) {
      return (option.name || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        || (option.timezone || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
    },
  },
};
</script>

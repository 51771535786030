export default [
  {
    id: 0,
    timezone: 'Pacific/Niue',
    country: 'NU',
    pin: '14,151',
    offset: -11,
    zoneName: 'NUT',
  },
  {
    id: 1,
    timezone: 'Pacific/Pago_Pago',
    country: 'AS',
    pin: '13,145',
    offset: -11,
    zoneName: 'SST',
  },
  {
    id: 2,
    timezone: 'Pacific/Rarotonga',
    country: 'CK',
    pin: '28,154',
    offset: -10,
    zoneName: 'CKT',
  },
  {
    id: 3,
    timezone: 'Pacific/Honolulu',
    country: 'US',
    pin: '31,95',
    offset: -10,
    zoneName: 'HST',
  },
  {
    id: 4,
    timezone: 'America/Adak',
    country: 'US',
    pin: '5,53',
    offset: -10,
    zoneName: 'HST',
  },
  {
    id: 5,
    timezone: 'Pacific/Tahiti',
    country: 'PF',
    pin: '42,149',
    offset: -10,
    zoneName: 'TAHT',
  },
  {
    id: 6,
    timezone: 'Pacific/Marquesas',
    country: 'PF',
    pin: '56,138',
    offset: -9.5,
    zoneName: 'MART',
  },
  {
    id: 7,
    timezone: 'America/Anchorage',
    country: 'US',
    pin: '42,40',
    offset: -9,
    zoneName: 'AKST',
  },
  {
    id: 8,
    timezone: 'America/Juneau',
    country: 'US',
    pin: '63,44',
    offset: -9,
    zoneName: 'AKST',
  },
  {
    id: 9,
    timezone: 'America/Metlakatla',
    country: 'US',
    pin: '67,48',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 10,
    timezone: 'America/Nome',
    country: 'US',
    pin: '20,35',
    offset: -9,
    zoneName: 'AKST',
  },
  {
    id: 11,
    timezone: 'America/Sitka',
    country: 'US',
    pin: '62,46',
    offset: -9,
    zoneName: 'AKST',
  },
  {
    id: 12,
    timezone: 'America/Yakutat',
    country: 'US',
    pin: '56,42',
    offset: -9,
    zoneName: 'AKST',
  },
  {
    id: 13,
    timezone: 'Pacific/Gambier',
    country: 'PF',
    pin: '63,157',
    offset: -9,
    zoneName: 'GAMT',
  },
  {
    id: 14,
    timezone: 'America/Los_Angeles',
    country: 'US',
    pin: '86,78',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 15,
    timezone: 'America/Tijuana',
    country: 'MX',
    pin: '87,80',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 16,
    timezone: 'America/Vancouver',
    country: 'CA',
    pin: '79,57',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 17,
    timezone: 'Pacific/Pitcairn',
    country: 'PN',
    pin: '69,160',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 18,
    timezone: 'America/Hermosillo',
    country: 'MX',
    pin: '96,85',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 19,
    timezone: 'America/Mazatlan',
    country: 'MX',
    pin: '102,93',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 20,
    timezone: 'America/Dawson_Creek',
    country: 'CA',
    pin: '83,42',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 21,
    timezone: 'America/Fort_Nelson',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 22,
    timezone: 'America/Phoenix',
    country: 'US',
    pin: '94,79',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 23,
    timezone: 'America/Boise',
    country: 'US',
    pin: '89,64',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 24,
    timezone: 'America/Cambridge_Bay',
    country: 'CA',
    pin: '104,29',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 25,
    timezone: 'America/Ciudad_Juarez',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 26,
    timezone: 'America/Denver',
    country: 'US',
    pin: '104,70',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 27,
    timezone: 'America/Edmonton',
    country: 'CA',
    pin: '92,51',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 28,
    timezone: 'America/Yellowknife',
    country: 'CA',
    pin: '91,38',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 29,
    timezone: 'America/Inuvik',
    country: 'CA',
    pin: '64,30',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 30,
    timezone: 'America/Dawson',
    country: 'CA',
    pin: '56,36',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 31,
    timezone: 'America/Whitehorse',
    country: 'CA',
    pin: '62,41',
    offset: -8,
    zoneName: 'PST',
  },
  {
    id: 32,
    timezone: 'America/Bahia_Banderas',
    country: 'MX',
    pin: '104,96',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 33,
    timezone: 'America/Belize',
    country: 'BZ',
    pin: '128,101',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 34,
    timezone: 'America/Chihuahua',
    country: 'MX',
    pin: '103,85',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 35,
    timezone: 'America/Costa_Rica',
    country: 'CR',
    pin: '133,111',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 36,
    timezone: 'America/El_Salvador',
    country: 'SV',
    pin: '126,106',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 37,
    timezone: 'America/Guatemala',
    country: 'GT',
    pin: '124,105',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 38,
    timezone: 'America/Managua',
    country: 'NI',
    pin: '130,108',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 39,
    timezone: 'America/Merida',
    country: 'MX',
    pin: '126,96',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 40,
    timezone: 'America/Mexico_City',
    country: 'MX',
    pin: '112,98',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 41,
    timezone: 'America/Monterrey',
    country: 'MX',
    pin: '111,89',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 42,
    timezone: 'America/Regina',
    country: 'CA',
    pin: '105,55',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 43,
    timezone: 'America/Swift_Current',
    country: 'CA',
    pin: '100,55',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 44,
    timezone: 'America/Tegucigalpa',
    country: 'HN',
    pin: '129,105',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 45,
    timezone: 'America/North_Dakota/Beulah',
    country: 'US',
    pin: '109,59',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 46,
    timezone: 'America/North_Dakota/Center',
    country: 'US',
    pin: '109,60',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 47,
    timezone: 'America/Chicago',
    country: 'US',
    pin: '128,67',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 48,
    timezone: 'America/Indiana/Knox',
    country: 'US',
    pin: '130,68',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 49,
    timezone: 'America/Matamoros',
    country: 'MX',
    pin: '115,89',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 50,
    timezone: 'America/Menominee',
    country: 'US',
    pin: '128,62',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 51,
    timezone: 'America/North_Dakota/New_Salem',
    country: 'US',
    pin: '109,60',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 52,
    timezone: 'America/Ojinaga',
    country: 'MX',
    pin: '105,84',
    offset: -7,
    zoneName: 'MST',
  },
  {
    id: 53,
    timezone: 'America/Rankin_Inlet',
    country: 'CA',
    pin: '122,38',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 54,
    timezone: 'America/Resolute',
    country: 'CA',
    pin: '118,21',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 55,
    timezone: 'America/Indiana/Tell_City',
    country: 'US',
    pin: '129,72',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 56,
    timezone: 'America/Rainy_River',
    country: 'CA',
    pin: '119,57',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 57,
    timezone: 'America/Winnipeg',
    country: 'CA',
    pin: '115,56',
    offset: -6,
    zoneName: 'CST',
  },
  {
    id: 58,
    timezone: 'Pacific/Galapagos',
    country: 'EC',
    pin: '126,126',
    offset: -6,
    zoneName: 'GALT',
  },
  {
    id: 59,
    timezone: 'America/Eirunepe',
    country: 'BR',
    pin: '153,134',
    offset: -4,
    zoneName: 'ACT',
  },
  {
    id: 60,
    timezone: 'America/Rio_Branco',
    country: 'BR',
    pin: '156,139',
    offset: -4,
    zoneName: 'ACT',
  },
  {
    id: 61,
    timezone: 'America/Bogota',
    country: 'CO',
    pin: '147,119',
    offset: -5,
    zoneName: 'COT',
  },
  {
    id: 62,
    timezone: 'America/Havana',
    country: 'CU',
    pin: '136,93',
    offset: -5,
    zoneName: 'CST',
  },
  {
    id: 63,
    timezone: 'Pacific/Easter',
    country: 'CL',
    pin: '98,163',
    offset: -5,
    zoneName: 'EAST',
  },
  {
    id: 64,
    timezone: 'America/Cancun',
    country: 'MX',
    pin: '129,96',
    offset: -6,
    zoneName: 'EST',
  },
  {
    id: 65,
    timezone: 'America/Jamaica',
    country: 'JM',
    pin: '143,100',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 66,
    timezone: 'America/Panama',
    country: 'PA',
    pin: '140,113',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 67,
    timezone: 'America/Detroit',
    country: 'US',
    pin: '135,66',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 68,
    timezone: 'America/Grand_Turk',
    country: 'TC',
    pin: '151,95',
    offset: -5,
    zoneName: 'AST',
  },
  {
    id: 69,
    timezone: 'America/Indiana/Indianapolis',
    country: 'US',
    pin: '130,70',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 70,
    timezone: 'America/Iqaluit',
    country: 'CA',
    pin: '155,36',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 71,
    timezone: 'America/Pangnirtung',
    country: 'CA',
    pin: '159,33',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 72,
    timezone: 'America/Kentucky/Louisville',
    country: 'US',
    pin: '131,72',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 73,
    timezone: 'America/Indiana/Marengo',
    country: 'US',
    pin: '130,72',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 74,
    timezone: 'America/Kentucky/Monticello',
    country: 'US',
    pin: '132,74',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 75,
    timezone: 'America/New_York',
    country: 'US',
    pin: '147,68',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 76,
    timezone: 'America/Indiana/Petersburg',
    country: 'US',
    pin: '129,72',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 77,
    timezone: 'America/Port-au-Prince',
    country: 'HT',
    pin: '150,99',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 78,
    timezone: 'America/Nipigon',
    country: 'CA',
    pin: '127,57',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 79,
    timezone: 'America/Thunder_Bay',
    country: 'CA',
    pin: '126,58',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 80,
    timezone: 'America/Toronto',
    country: 'CA',
    pin: '140,64',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 81,
    timezone: 'America/Indiana/Vevay',
    country: 'US',
    pin: '132,71',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 82,
    timezone: 'America/Indiana/Vincennes',
    country: 'US',
    pin: '128,71',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 83,
    timezone: 'America/Indiana/Winamac',
    country: 'US',
    pin: '130,68',
    offset: -5,
    zoneName: 'EST',
  },
  {
    id: 84,
    timezone: 'America/Guayaquil',
    country: 'EC',
    pin: '139,128',
    offset: -5,
    zoneName: 'ECT',
  },
  {
    id: 85,
    timezone: 'America/Lima',
    country: 'PE',
    pin: '143,142',
    offset: -5,
    zoneName: 'PET',
  },
  {
    id: 86,
    timezone: 'America/Boa_Vista',
    country: 'BR',
    pin: '166,121',
    offset: -4,
    zoneName: 'AMT',
  },
  {
    id: 87,
    timezone: 'America/Campo_Grande',
    country: 'BR',
    pin: '174,153',
    offset: -3,
    zoneName: 'AMST',
  },
  {
    id: 88,
    timezone: 'America/Cuiaba',
    country: 'BR',
    pin: '172,147',
    offset: -3,
    zoneName: 'AMST',
  },
  {
    id: 89,
    timezone: 'America/Manaus',
    country: 'BR',
    pin: '167,129',
    offset: -4,
    zoneName: 'AMT',
  },
  {
    id: 90,
    timezone: 'America/Porto_Velho',
    country: 'BR',
    pin: '161,137',
    offset: -4,
    zoneName: 'AMT',
  },
  {
    id: 91,
    timezone: 'America/Barbados',
    country: 'BB',
    pin: '167,107',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 92,
    timezone: 'America/Martinique',
    country: 'MQ',
    pin: '165,105',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 93,
    timezone: 'America/Puerto_Rico',
    country: 'PR',
    pin: '158,99',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 94,
    timezone: 'America/Santo_Domingo',
    country: 'DO',
    pin: '153,99',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 95,
    timezone: 'Atlantic/Bermuda',
    country: 'BM',
    pin: '160,80',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 96,
    timezone: 'America/Glace_Bay',
    country: 'CA',
    pin: '167,61',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 97,
    timezone: 'America/Goose_Bay',
    country: 'CA',
    pin: '166,51',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 98,
    timezone: 'America/Halifax',
    country: 'CA',
    pin: '162,63',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 99,
    timezone: 'America/Moncton',
    country: 'CA',
    pin: '160,61',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 100,
    timezone: 'America/Thule',
    country: 'GL',
    pin: '154,19',
    offset: -4,
    zoneName: 'AST',
  },
  {
    id: 101,
    timezone: 'America/La_Paz',
    country: 'BO',
    pin: '155,148',
    offset: -4,
    zoneName: 'BOT',
  },
  {
    id: 102,
    timezone: 'America/Guyana',
    country: 'GY',
    pin: '169,116',
    offset: -4,
    zoneName: 'GYT',
  },
  {
    id: 103,
    timezone: 'America/Caracas',
    country: 'VE',
    pin: '157,110',
    offset: -4,
    zoneName: 'VET',
  },
  {
    id: 104,
    timezone: 'America/St_Johns',
    country: 'CA',
    pin: '177,59',
    offset: -3.5,
    zoneName: 'NST',
  },
  {
    id: 105,
    timezone: 'America/Argentina/Buenos_Aires',
    country: 'AR',
    pin: '169,173',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 106,
    timezone: 'America/Argentina/Catamarca',
    country: 'AR',
    pin: '159,165',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 107,
    timezone: 'America/Argentina/Cordoba',
    country: 'AR',
    pin: '161,169',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 108,
    timezone: 'America/Argentina/Jujuy',
    country: 'AR',
    pin: '159,159',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 109,
    timezone: 'America/Argentina/La_Rioja',
    country: 'AR',
    pin: '157,166',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 110,
    timezone: 'America/Argentina/Mendoza',
    country: 'AR',
    pin: '154,171',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 111,
    timezone: 'America/Argentina/Rio_Gallegos',
    country: 'AR',
    pin: '154,197',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 112,
    timezone: 'America/Argentina/Salta',
    country: 'AR',
    pin: '159,159',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 113,
    timezone: 'America/Argentina/San_Juan',
    country: 'AR',
    pin: '155,169',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 114,
    timezone: 'America/Argentina/San_Luis',
    country: 'AR',
    pin: '158,171',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 115,
    timezone: 'America/Argentina/Tucuman',
    country: 'AR',
    pin: '159,162',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 116,
    timezone: 'America/Argentina/Ushuaia',
    country: 'AR',
    pin: '155,201',
    offset: -3,
    zoneName: 'ART',
  },
  {
    id: 117,
    timezone: 'America/Araguaina',
    country: 'BR',
    pin: '183,135',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 118,
    timezone: 'America/Bahia',
    country: 'BR',
    pin: '197,143',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 119,
    timezone: 'America/Belem',
    country: 'BR',
    pin: '183,127',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 120,
    timezone: 'America/Fortaleza',
    country: 'BR',
    pin: '197,130',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 121,
    timezone: 'America/Maceio',
    country: 'BR',
    pin: '200,138',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 122,
    timezone: 'America/Recife',
    country: 'BR',
    pin: '202,136',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 123,
    timezone: 'America/Santarem',
    country: 'BR',
    pin: '174,128',
    offset: -3,
    zoneName: 'BRT',
  },
  {
    id: 124,
    timezone: 'America/Sao_Paulo',
    country: 'BR',
    pin: '185,158',
    offset: -2,
    zoneName: 'BRST',
  },
  {
    id: 125,
    timezone: 'America/Santiago',
    country: 'CL',
    pin: '152,171',
    offset: -3,
    zoneName: 'CLT',
  },
  {
    id: 126,
    timezone: 'Atlantic/Stanley',
    country: 'FK',
    pin: '170,197',
    offset: -3,
    zoneName: 'FKST',
  },
  {
    id: 127,
    timezone: 'America/Cayenne',
    country: 'GF',
    pin: '177,118',
    offset: -3,
    zoneName: 'GFT',
  },
  {
    id: 128,
    timezone: 'Antarctica/Palmer',
    offset: -3,
    zoneName: 'GFT',
  },
  {
    id: 129,
    timezone: 'America/Asuncion',
    country: 'PY',
    pin: '170,160',
    offset: -3,
    zoneName: 'PYST',
  },
  {
    id: 130,
    timezone: 'America/Punta_Arenas',
    offset: -3,
  },
  {
    id: 131,
    timezone: 'Antarctica/Rothera',
    offset: -3,
  },
  {
    id: 132,
    timezone: 'America/Miquelon',
    country: 'PM',
    pin: '172,60',
    offset: -3,
    zoneName: 'PMST',
  },
  {
    id: 133,
    timezone: 'America/Paramaribo',
    country: 'SR',
    pin: '173,117',
    offset: -3,
    zoneName: 'SRT',
  },
  {
    id: 134,
    timezone: 'America/Montevideo',
    country: 'UY',
    pin: '172,173',
    offset: -2,
    zoneName: 'UYT',
  },
  {
    id: 135,
    timezone: 'America/Noronha',
    country: 'BR',
    pin: '205,130',
    offset: -2,
    zoneName: 'FNT',
  },
  {
    id: 136,
    timezone: 'Atlantic/South_Georgia',
    country: 'GS',
    pin: '199,200',
    offset: -2,
    zoneName: 'GST',
  },
  {
    id: 137,
    timezone: 'America/Nuuk',
    offset: -2,
  },
  {
    id: 138,
    timezone: 'Atlantic/Azores',
    country: 'PT',
    pin: '214,73',
    offset: -1,
    zoneName: 'AZOT',
  },
  {
    id: 139,
    timezone: 'Atlantic/Cape_Verde',
    country: 'CV',
    pin: '217,104',
    offset: -1,
    zoneName: 'CVT',
  },
  {
    id: 140,
    timezone: 'America/Scoresbysund',
    country: 'GL',
    pin: '219,27',
    offset: -1,
    zoneName: 'EGT',
  },
  {
    id: 141,
    timezone: 'UTC',
    offset: 0,
  },
  {
    id: 142,
    timezone: 'Etc/GMT',
    offset: 0,
  },
  {
    id: 143,
    timezone: 'Africa/Abidjan',
    country: 'CI',
    pin: '244,118',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 144,
    timezone: 'Africa/Bissau',
    country: 'GW',
    pin: '228,109',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 145,
    timezone: 'America/Danmarkshavn',
    country: 'GL',
    pin: '224,18',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 146,
    timezone: 'Africa/Monrovia',
    country: 'LR',
    pin: '235,116',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 147,
    timezone: 'Atlantic/Reykjavik',
    country: 'IS',
    pin: '220,36',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 148,
    timezone: 'Africa/Sao_Tome',
    country: 'ST',
    pin: '259,125',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 149,
    timezone: 'Europe/Dublin',
    country: 'IE',
    pin: '241,51',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 150,
    timezone: 'Antarctica/Troll',
    offset: 0,
  },
  {
    id: 151,
    timezone: 'Europe/London',
    country: 'GB',
    pin: '250,53',
    offset: 0,
    zoneName: 'GMT',
  },
  {
    id: 152,
    timezone: 'Atlantic/Canary',
    country: 'ES',
    pin: '229,86',
    offset: 0,
    zoneName: 'WET',
  },
  {
    id: 153,
    timezone: 'Atlantic/Faroe',
    country: 'FO',
    pin: '241,39',
    offset: 0,
    zoneName: 'WET',
  },
  {
    id: 154,
    timezone: 'Europe/Lisbon',
    country: 'PT',
    pin: '237,71',
    offset: 0,
    zoneName: 'WET',
  },
  {
    id: 155,
    timezone: 'Atlantic/Madeira',
    country: 'PT',
    pin: '227,80',
    offset: 0,
    zoneName: 'WET',
  },
  {
    id: 156,
    timezone: 'Africa/Algiers',
    country: 'DZ',
    pin: '254,74',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 157,
    timezone: 'Africa/Tunis',
    country: 'TN',
    pin: '264,74',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 158,
    timezone: 'Europe/Amsterdam',
    country: 'NL',
    pin: '257,52',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 159,
    timezone: 'Europe/Andorra',
    country: 'AD',
    pin: '252,66',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 160,
    timezone: 'Europe/Belgrade',
    country: 'RS',
    pin: '278,63',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 161,
    timezone: 'Europe/Berlin',
    country: 'DE',
    pin: '269,52',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 162,
    timezone: 'Europe/Brussels',
    country: 'BE',
    pin: '256,54',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 163,
    timezone: 'Europe/Budapest',
    country: 'HU',
    pin: '277,59',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 164,
    timezone: 'Africa/Ceuta',
    country: 'ES',
    pin: '243,75',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 165,
    timezone: 'Europe/Copenhagen',
    country: 'DK',
    pin: '267,48',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 166,
    timezone: 'Europe/Gibraltar',
    country: 'GI',
    pin: '243,75',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 167,
    timezone: 'Europe/Luxembourg',
    country: 'LU',
    pin: '259,56',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 168,
    timezone: 'Europe/Madrid',
    country: 'ES',
    pin: '245,69',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 169,
    timezone: 'Europe/Malta',
    country: 'MT',
    pin: '270,75',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 170,
    timezone: 'Europe/Monaco',
    country: 'MC',
    pin: '260,64',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 171,
    timezone: 'Europe/Oslo',
    country: 'NO',
    pin: '265,42',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 172,
    timezone: 'Europe/Paris',
    country: 'FR',
    pin: '253,57',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 173,
    timezone: 'Europe/Prague',
    country: 'CZ',
    pin: '270,55',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 174,
    timezone: 'Europe/Rome',
    country: 'IT',
    pin: '267,67',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 175,
    timezone: 'Europe/Stockholm',
    country: 'SE',
    pin: '275,43',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 176,
    timezone: 'Europe/Tirane',
    country: 'AL',
    pin: '278,68',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 177,
    timezone: 'Europe/Vienna',
    country: 'AT',
    pin: '273,58',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 178,
    timezone: 'Europe/Warsaw',
    country: 'PL',
    pin: '279,52',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 179,
    timezone: 'Europe/Zurich',
    country: 'CH',
    pin: '262,59',
    offset: 1,
    zoneName: 'CET',
  },
  {
    id: 180,
    timezone: 'Africa/Casablanca',
    country: 'MA',
    pin: '239,78',
    offset: 0,
    zoneName: 'WET',
  },
  {
    id: 181,
    timezone: 'Africa/Lagos',
    country: 'NG',
    pin: '255,116',
    offset: 1,
    zoneName: 'WAT',
  },
  {
    id: 182,
    timezone: 'Africa/Ndjamena',
    country: 'TD',
    pin: '271,108',
    offset: 1,
    zoneName: 'WAT',
  },
  {
    id: 183,
    timezone: 'Africa/El_Aaiun',
    country: 'EH',
    pin: '232,87',
    offset: 0,
    zoneName: 'WET',
  },
  {
    id: 184,
    timezone: 'Africa/Juba',
    country: 'SS',
    pin: '294,118',
    offset: 3,
    zoneName: 'EAT',
  },
  {
    id: 185,
    timezone: 'Africa/Khartoum',
    country: 'SD',
    pin: '295,103',
    offset: 3,
    zoneName: 'EAT',
  },
  {
    id: 186,
    timezone: 'Africa/Maputo',
    country: 'MZ',
    pin: '295,161',
    offset: 2,
    zoneName: 'CAT',
  },
  {
    id: 187,
    timezone: 'Africa/Windhoek',
    country: 'NA',
    pin: '274,156',
    offset: 2,
    zoneName: 'WAST',
  },
  {
    id: 188,
    timezone: 'Europe/Kaliningrad',
    country: 'RU',
    pin: '278,49',
    offset: 3,
    zoneName: 'EET',
  },
  {
    id: 189,
    timezone: 'Africa/Tripoli',
    country: 'LY',
    pin: '268,79',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 190,
    timezone: 'Europe/Athens',
    country: 'GR',
    pin: '283,72',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 191,
    timezone: 'Asia/Beirut',
    country: 'LB',
    pin: '299,78',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 192,
    timezone: 'Europe/Bucharest',
    country: 'RO',
    pin: '286,63',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 193,
    timezone: 'Africa/Cairo',
    country: 'EG',
    pin: '293,83',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 194,
    timezone: 'Europe/Chisinau',
    country: 'MD',
    pin: '290,60',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 195,
    timezone: 'Asia/Gaza',
    country: 'PS',
    pin: '298,81',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 196,
    timezone: 'Asia/Hebron',
    country: 'PS',
    pin: '299,81',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 197,
    timezone: 'Europe/Helsinki',
    country: 'FI',
    pin: '285,41',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 198,
    timezone: 'Europe/Kiev',
    country: 'UA',
    pin: '292,55',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 199,
    timezone: 'Europe/Uzhgorod',
    country: 'UA',
    pin: '281,57',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 200,
    timezone: 'Europe/Zaporozhye',
    country: 'UA',
    pin: '299,59',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 201,
    timezone: 'Asia/Nicosia',
    country: 'CY',
    pin: '296,76',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 202,
    timezone: 'Europe/Riga',
    country: 'LV',
    pin: '283,46',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 203,
    timezone: 'Europe/Sofia',
    country: 'BG',
    pin: '282,66',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 204,
    timezone: 'Europe/Tallinn',
    country: 'EE',
    pin: '284,42',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 205,
    timezone: 'Europe/Vilnius',
    country: 'LT',
    pin: '285,49',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 206,
    timezone: 'Asia/Famagusta',
    offset: 2,
  },
  {
    id: 207,
    timezone: 'Asia/Jerusalem',
    country: 'IL',
    pin: '299,81',
    offset: 2,
    zoneName: 'IST',
  },
  {
    id: 208,
    timezone: 'Africa/Johannesburg',
    country: 'ZA',
    pin: '289,161',
    offset: 2,
    zoneName: 'SAST',
  },
  {
    id: 209,
    timezone: 'Asia/Baghdad',
    country: 'IQ',
    pin: '312,79',
    offset: 3,
    zoneName: 'AST',
  },
  {
    id: 210,
    timezone: 'Asia/Qatar',
    country: 'QA',
    pin: '322,90',
    offset: 3,
    zoneName: 'AST',
  },
  {
    id: 211,
    timezone: 'Asia/Riyadh',
    country: 'SA',
    pin: '315,91',
    offset: 3,
    zoneName: 'AST',
  },
  {
    id: 212,
    timezone: 'Africa/Nairobi',
    country: 'KE',
    pin: '301,127',
    offset: 3,
    zoneName: 'EAT',
  },
  {
    id: 213,
    timezone: 'Asia/Amman',
    country: 'JO',
    pin: '300,81',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 214,
    timezone: 'Europe/Kirov',
    offset: 2,
  },
  {
    id: 215,
    timezone: 'Europe/Minsk',
    country: 'BY',
    pin: '288,50',
    offset: 3,
    zoneName: 'MSK',
  },
  {
    id: 216,
    timezone: 'Europe/Moscow',
    country: 'RU',
    pin: '302,48',
    offset: 4,
    zoneName: 'MSK',
  },
  {
    id: 217,
    timezone: 'Europe/Simferopol',
    country: 'RU',
    pin: '297,63',
    offset: 2,
    zoneName: 'MSK',
  },
  {
    id: 218,
    timezone: 'Asia/Damascus',
    country: 'SY',
    pin: '300,78',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 219,
    timezone: 'Europe/Istanbul',
    country: 'TR',
    pin: '290,68',
    offset: 2,
    zoneName: 'EET',
  },
  {
    id: 220,
    timezone: 'Europe/Volgograd',
    country: 'RU',
    pin: '312,57',
    offset: 4,
    zoneName: 'MSK',
  },
  {
    id: 221,
    timezone: 'Asia/Tehran',
    country: 'IR',
    pin: '321,75',
    offset: 3.5,
    zoneName: 'IRST',
  },
  {
    id: 222,
    timezone: 'Asia/Yerevan',
    country: 'AM',
    pin: '312,69',
    offset: 4,
    zoneName: 'AMT',
  },
  {
    id: 223,
    timezone: 'Europe/Astrakhan',
    offset: 4,
  },
  {
    id: 224,
    timezone: 'Asia/Baku',
    country: 'AZ',
    pin: '319,69',
    offset: 4,
    zoneName: 'AZT',
  },
  {
    id: 225,
    timezone: 'Asia/Tbilisi',
    country: 'GE',
    pin: '312,67',
    offset: 4,
    zoneName: 'GET',
  },
  {
    id: 226,
    timezone: 'Asia/Dubai',
    country: 'AE',
    pin: '327,90',
    offset: 4,
    zoneName: 'GST',
  },
  {
    id: 227,
    timezone: 'Indian/Mauritius',
    country: 'MU',
    pin: '330,153',
    offset: 4,
    zoneName: 'MUT',
  },
  {
    id: 228,
    timezone: 'Indian/Reunion',
    country: 'RE',
    pin: '327,154',
    offset: 4,
    zoneName: 'RET',
  },
  {
    id: 229,
    timezone: 'Europe/Samara',
    country: 'RU',
    pin: '320,51',
    offset: 4,
    zoneName: 'SAMT',
  },
  {
    id: 230,
    timezone: 'Europe/Saratov',
    offset: 4,
  },
  {
    id: 231,
    timezone: 'Indian/Mahe',
    country: 'SC',
    pin: '327,131',
    offset: 4,
    zoneName: 'SCT',
  },
  {
    id: 232,
    timezone: 'Europe/Ulyanovsk',
    offset: 4,
  },
  {
    id: 233,
    timezone: 'Asia/Kabul',
    country: 'AF',
    pin: '346,77',
    offset: 4.5,
    zoneName: 'AFT',
  },
  {
    id: 234,
    timezone: 'Indian/Kerguelen',
    country: 'TF',
    pin: '348,194',
    offset: 5,
    zoneName: 'TFT',
  },
  {
    id: 235,
    timezone: 'Indian/Maldives',
    country: 'MV',
    pin: '352,119',
    offset: 5,
    zoneName: 'MVT',
  },
  {
    id: 236,
    timezone: 'Antarctica/Mawson',
    offset: 5,
  },
  {
    id: 237,
    timezone: 'Asia/Karachi',
    country: 'PK',
    pin: '343,90',
    offset: 5,
    zoneName: 'PKT',
  },
  {
    id: 238,
    timezone: 'Asia/Dushanbe',
    country: 'TJ',
    pin: '346,71',
    offset: 5,
    zoneName: 'TJT',
  },
  {
    id: 239,
    timezone: 'Asia/Ashgabat',
    country: 'TM',
    pin: '331,72',
    offset: 5,
    zoneName: 'TMT',
  },
  {
    id: 240,
    timezone: 'Asia/Samarkand',
    country: 'UZ',
    pin: '343,70',
    offset: 5,
    zoneName: 'UZT',
  },
  {
    id: 241,
    timezone: 'Asia/Tashkent',
    country: 'UZ',
    pin: '346,68',
    offset: 5,
    zoneName: 'UZT',
  },
  {
    id: 242,
    timezone: 'Asia/Aqtau',
    country: 'KZ',
    pin: '320,63',
    offset: 5,
    zoneName: 'AQTT',
  },
  {
    id: 243,
    timezone: 'Asia/Aqtobe',
    country: 'KZ',
    pin: '329,55',
    offset: 5,
    zoneName: 'AQTT',
  },
  {
    id: 244,
    timezone: 'Asia/Atyrau',
    offset: 5,
  },
  {
    id: 245,
    timezone: 'Asia/Oral',
    country: 'KZ',
    pin: '321,54',
    offset: 5,
    zoneName: 'ORAT',
  },
  {
    id: 246,
    timezone: 'Asia/Qyzylorda',
    country: 'KZ',
    pin: '341,63',
    offset: 6,
    zoneName: 'QYZT',
  },
  {
    id: 247,
    timezone: 'Asia/Yekaterinburg',
    country: 'RU',
    pin: '334,46',
    offset: 6,
    zoneName: 'YEKT',
  },
  {
    id: 248,
    timezone: 'Asia/Colombo',
    country: 'LK',
    pin: '361,115',
    offset: 5.5,
    zoneName: 'IST',
  },
  {
    id: 249,
    timezone: 'Asia/Kolkata',
  },
  {
    id: 250,
    timezone: 'Asia/Kathmandu',
    country: 'NP',
    pin: '368,87',
    offset: 5.8,
    zoneName: 'NPT',
  },
  {
    id: 251,
    timezone: 'Asia/Dhaka',
    country: 'BD',
    pin: '376,92',
    offset: 6,
    zoneName: 'BDT',
  },
  {
    id: 252,
    timezone: 'Asia/Thimphu',
    country: 'BT',
    pin: '375,87',
    offset: 6,
    zoneName: 'BTT',
  },
  {
    id: 253,
    timezone: 'Asia/Almaty',
    country: 'KZ',
    pin: '357,65',
    offset: 6,
    zoneName: 'ALMT',
  },
  {
    id: 254,
    timezone: 'Asia/Qostanay',
    offset: 6,
  },
  {
    id: 255,
    timezone: 'Indian/Chagos',
    country: 'IO',
    pin: '351,135',
    offset: 6,
    zoneName: 'IOT',
  },
  {
    id: 256,
    timezone: 'Asia/Bishkek',
    country: 'KG',
    pin: '354,65',
    offset: 6,
    zoneName: 'KGT',
  },
  {
    id: 257,
    timezone: 'Asia/Omsk',
    country: 'RU',
    pin: '352,49',
    offset: 7,
    zoneName: 'OMST',
  },
  {
    id: 258,
    timezone: 'Asia/Urumqi',
    country: 'CN',
    pin: '372,64',
    offset: 6,
    zoneName: 'XJT',
  },
  {
    id: 259,
    timezone: 'Antarctica/Vostok',
    offset: 6,
  },
  {
    id: 260,
    timezone: 'Indian/Cocos',
    country: 'CC',
    pin: '385,142',
    offset: 6.5,
    zoneName: 'CCT',
  },
  {
    id: 261,
    timezone: 'Asia/Yangon',
    offset: 6.5,
  },
  {
    id: 262,
    timezone: 'Asia/Barnaul',
    offset: 7,
  },
  {
    id: 263,
    timezone: 'Indian/Christmas',
    country: 'CX',
    pin: '397,139',
    offset: 7,
    zoneName: 'CXT',
  },
  {
    id: 264,
    timezone: 'Antarctica/Davis',
    offset: 7,
  },
  {
    id: 265,
    timezone: 'Asia/Hovd',
    country: 'MN',
    pin: '377,58',
    offset: 7,
    zoneName: 'HOVT',
  },
  {
    id: 266,
    timezone: 'Asia/Bangkok',
    country: 'TH',
    pin: '390,106',
    offset: 7,
    zoneName: 'ICT',
  },
  {
    id: 267,
    timezone: 'Asia/Ho_Chi_Minh',
    country: 'VN',
    pin: '398,110',
    offset: 7,
    zoneName: 'ICT',
  },
  {
    id: 268,
    timezone: 'Asia/Krasnoyarsk',
    country: 'RU',
    pin: '379,47',
    offset: 8,
    zoneName: 'KRAT',
  },
  {
    id: 269,
    timezone: 'Asia/Novokuznetsk',
    country: 'RU',
    pin: '371,50',
    offset: 7,
    zoneName: 'KRAT',
  },
  {
    id: 270,
    timezone: 'Asia/Novosibirsk',
    country: 'RU',
    pin: '365,49',
    offset: 7,
    zoneName: 'NOVT',
  },
  {
    id: 271,
    timezone: 'Asia/Tomsk',
    offset: 7,
  },
  {
    id: 272,
    timezone: 'Asia/Jakarta',
    country: 'ID',
    pin: '398,134',
    offset: 7,
    zoneName: 'WIB',
  },
  {
    id: 273,
    timezone: 'Asia/Pontianak',
    country: 'ID',
    pin: '402,125',
    offset: 7,
    zoneName: 'WIB',
  },
  {
    id: 274,
    timezone: 'Australia/Perth',
    country: 'AU',
    pin: '411,169',
    offset: 8,
    zoneName: 'AWST',
  },
  {
    id: 275,
    timezone: 'Asia/Brunei',
    country: 'BN',
    pin: '410,118',
    offset: 8,
    zoneName: 'BNT',
  },
  {
    id: 276,
    timezone: 'Asia/Makassar',
    country: 'ID',
    pin: '416,132',
    offset: 8,
    zoneName: 'WITA',
  },
  {
    id: 277,
    timezone: 'Asia/Macau',
    country: 'MO',
    pin: '408,94',
    offset: 8,
    zoneName: 'CST',
  },
  {
    id: 278,
    timezone: 'Asia/Shanghai',
    country: 'CN',
    pin: '419,82',
    offset: 8,
    zoneName: 'CST',
  },
  {
    id: 279,
    timezone: 'Asia/Hong_Kong',
    country: 'HK',
    pin: '409,94',
    offset: 8,
    zoneName: 'HKT',
  },
  {
    id: 280,
    timezone: 'Asia/Irkutsk',
    country: 'RU',
    pin: '395,52',
    offset: 9,
    zoneName: 'IRKT',
  },
  {
    id: 281,
    timezone: 'Asia/Kuala_Lumpur',
    country: 'MY',
    pin: '391,121',
    offset: 8,
    zoneName: 'MYT',
  },
  {
    id: 282,
    timezone: 'Asia/Kuching',
    country: 'MY',
    pin: '403,123',
    offset: 8,
    zoneName: 'MYT',
  },
  {
    id: 283,
    timezone: 'Asia/Manila',
    country: 'PH',
    pin: '418,105',
    offset: 8,
    zoneName: 'PHT',
  },
  {
    id: 284,
    timezone: 'Asia/Singapore',
    country: 'SG',
    pin: '394,123',
    offset: 8,
    zoneName: 'SGT',
  },
  {
    id: 285,
    timezone: 'Asia/Taipei',
    country: 'TW',
    pin: '419,90',
    offset: 8,
    zoneName: 'CST',
  },
  {
    id: 286,
    timezone: 'Asia/Choibalsan',
    country: 'MN',
    pin: '409,58',
    offset: 8,
    zoneName: 'CHOT',
  },
  {
    id: 287,
    timezone: 'Asia/Ulaanbaatar',
    country: 'MN',
    pin: '398,58',
    offset: 8,
    zoneName: 'ULAT',
  },
  {
    id: 288,
    timezone: 'Australia/Eucla',
    country: 'AU',
    pin: '429,169',
    offset: 8.8,
    zoneName: 'ACWST',
  },
  {
    id: 289,
    timezone: 'Asia/Dili',
    country: 'TL',
    pin: '424,137',
    offset: 9,
    zoneName: 'TLT',
  },
  {
    id: 290,
    timezone: 'Asia/Jayapura',
    country: 'ID',
    pin: '445,129',
    offset: 9,
    zoneName: 'WIT',
  },
  {
    id: 291,
    timezone: 'Asia/Tokyo',
    country: 'JP',
    pin: '444,75',
    offset: 9,
    zoneName: 'JST',
  },
  {
    id: 292,
    timezone: 'Asia/Pyongyang',
    country: 'KP',
    pin: '425,71',
    offset: 9,
    zoneName: 'KST',
  },
  {
    id: 293,
    timezone: 'Asia/Seoul',
    country: 'KR',
    pin: '426,73',
    offset: 9,
    zoneName: 'KST',
  },
  {
    id: 294,
    timezone: 'Pacific/Palau',
    country: 'PW',
    pin: '437,115',
    offset: 9,
    zoneName: 'PWT',
  },
  {
    id: 295,
    timezone: 'Asia/Chita',
    offset: 9,
  },
  {
    id: 296,
    timezone: 'Asia/Khandyga',
    offset: 9,
  },
  {
    id: 297,
    timezone: 'Asia/Yakutsk',
    country: 'RU',
    pin: '430,39',
    offset: 9,
    zoneName: 'YAKT',
  },
  {
    id: 298,
    timezone: 'Australia/Darwin',
    country: 'AU',
    pin: '432,142',
    offset: 9.5,
    zoneName: 'ACST',
  },
  {
    id: 299,
    timezone: 'Australia/Brisbane',
    country: 'AU',
    pin: '463,163',
    offset: 10,
    zoneName: 'AEST',
  },
  {
    id: 300,
    timezone: 'Australia/Lindeman',
    country: 'AU',
    pin: '457,153',
    offset: 10,
    zoneName: 'AEST',
  },
  {
    id: 301,
    timezone: 'Pacific/Guam',
    country: 'GU',
    pin: '451,106',
    offset: 10,
    zoneName: 'ChST',
  },
  {
    id: 302,
    timezone: 'Pacific/Chuuk',
    country: 'FM',
    pin: '461,115',
    offset: 10,
    zoneName: 'CHUT',
  },
  {
    id: 303,
    timezone: 'Pacific/Port_Moresby',
    country: 'PG',
    pin: '454,138',
    offset: 10,
    zoneName: 'PGT',
  },
  {
    id: 304,
    timezone: 'Asia/Ust-Nera',
    offset: 10,
  },
  {
    id: 305,
    timezone: 'Asia/Vladivostok',
    country: 'RU',
    pin: '433,65',
    offset: 10,
    zoneName: 'VLAT',
  },
  {
    id: 306,
    timezone: 'Australia/Adelaide',
    country: 'AU',
    pin: '442,173',
    offset: 10.5,
    zoneName: 'ACDT',
  },
  {
    id: 307,
    timezone: 'Australia/Broken_Hill',
    country: 'AU',
    pin: '446,169',
    offset: 10.5,
    zoneName: 'ACDT',
  },
  {
    id: 308,
    timezone: 'Pacific/Bougainville',
    offset: 11,
  },
  {
    id: 309,
    timezone: 'Antarctica/Casey',
    offset: 11,
  },
  {
    id: 310,
    timezone: 'Australia/Hobart',
    country: 'AU',
    pin: '455,185',
    offset: 11,
    zoneName: 'AEDT',
  },
  {
    id: 311,
    timezone: 'Antarctica/Macquarie',
    country: 'AU',
    pin: '471,201',
    offset: 11,
    zoneName: 'MIST',
  },
  {
    id: 312,
    timezone: 'Australia/Melbourne',
    country: 'AU',
    pin: '451,178',
    offset: 11,
    zoneName: 'AEDT',
  },
  {
    id: 313,
    timezone: 'Australia/Sydney',
    country: 'AU',
    pin: '460,172',
    offset: 11,
    zoneName: 'AEDT',
  },
  {
    id: 314,
    timezone: 'Pacific/Kosrae',
    country: 'FM',
    pin: '476,118',
    offset: 11,
    zoneName: 'KOST',
  },
  {
    id: 315,
    timezone: 'Australia/Lord_Howe',
    country: 'AU',
    pin: '471,169',
    offset: 11,
    zoneName: 'LHDT',
  },
  {
    id: 316,
    timezone: 'Asia/Magadan',
    country: 'RU',
    pin: '459,42',
    offset: 12,
    zoneName: 'MAGT',
  },
  {
    id: 317,
    timezone: 'Pacific/Noumea',
    country: 'NC',
    pin: '481,156',
    offset: 11,
    zoneName: 'NCT',
  },
  {
    id: 318,
    timezone: 'Pacific/Pohnpei',
    country: 'FM',
    pin: '470,115',
    offset: 11,
    zoneName: 'PONT',
  },
  {
    id: 319,
    timezone: 'Asia/Sakhalin',
    country: 'RU',
    pin: '448,60',
    offset: 11,
    zoneName: 'SAKT',
  },
  {
    id: 320,
    timezone: 'Pacific/Guadalcanal',
    country: 'SB',
    pin: '473,138',
    offset: 11,
    zoneName: 'SBT',
  },
  {
    id: 321,
    timezone: 'Asia/Srednekolymsk',
    offset: 11,
  },
  {
    id: 322,
    timezone: 'Pacific/Efate',
    country: 'VU',
    pin: '484,150',
    offset: 11,
    zoneName: 'VUT',
  },
  {
    id: 323,
    timezone: 'Asia/Anadyr',
    country: 'RU',
    pin: '497,35',
    offset: 12,
    zoneName: 'ANAT',
  },
  {
    id: 324,
    timezone: 'Pacific/Fiji',
    country: 'FJ',
    pin: '498,150',
    offset: 13,
    zoneName: 'FJST',
  },
  {
    id: 325,
    timezone: 'Pacific/Tarawa',
    country: 'KI',
    pin: '490,123',
    offset: 12,
    zoneName: 'GILT',
  },
  {
    id: 326,
    timezone: 'Pacific/Kwajalein',
    country: 'MH',
    pin: '482,112',
    offset: 12,
    zoneName: 'MHT',
  },
  {
    id: 327,
    timezone: 'Pacific/Majuro',
    country: 'MH',
    pin: '488,115',
    offset: 12,
    zoneName: 'MHT',
  },
  {
    id: 328,
    timezone: 'Pacific/Nauru',
    country: 'NR',
    pin: '482,126',
    offset: 12,
    zoneName: 'NRT',
  },
  {
    id: 329,
    timezone: 'Pacific/Norfolk',
    country: 'NF',
    pin: '483,165',
    offset: 11.5,
    zoneName: 'NFT',
  },
  {
    id: 330,
    timezone: 'Asia/Kamchatka',
    country: 'RU',
    pin: '470,51',
    offset: 12,
    zoneName: 'PETT',
  },
  {
    id: 331,
    timezone: 'Pacific/Funafuti',
    country: 'TV',
    pin: '499,137',
    offset: 12,
    zoneName: 'TVT',
  },
  {
    id: 332,
    timezone: 'Pacific/Wake',
    country: 'UM',
    pin: '481,98',
    offset: 12,
    zoneName: 'WAKT',
  },
  {
    id: 333,
    timezone: 'Pacific/Wallis',
    country: 'WF',
    pin: '5,143',
    offset: 12,
    zoneName: 'WFT',
  },
  {
    id: 334,
    timezone: 'Pacific/Apia',
    country: 'WS',
    pin: '11,144',
    offset: 14,
    zoneName: 'WSDT',
  },
  {
    id: 335,
    timezone: 'Pacific/Auckland',
    country: 'NZ',
    pin: '493,176',
    offset: 13,
    zoneName: 'NZDT',
  },
  {
    id: 336,
    timezone: 'Pacific/Kanton',
    offset: 13,
  },
  {
    id: 337,
    timezone: 'Pacific/Fakaofo',
    country: 'TK',
    pin: '12,138',
    offset: 13,
    zoneName: 'TKT',
  },
  {
    id: 338,
    timezone: 'Pacific/Tongatapu',
    country: 'TO',
    pin: '7,154',
    offset: 13,
    zoneName: 'TOT',
  },
  {
    id: 339,
    timezone: 'Pacific/Chatham',
    country: 'NZ',
    pin: '5,186',
    offset: 13.8,
    zoneName: 'CHADT',
  },
  {
    id: 340,
    timezone: 'Pacific/Kiritimati',
    country: 'KI',
    pin: '31,122',
    offset: 14,
    zoneName: 'LINT',
  },
];

<template>
  <vx-tooltip
    :color="color"
    position="top"
    class="flex">
    <template v-slot:inner>
      <div class="p-2">
        <p class="font-black">{{ title }}</p>

        <div
          v-if="name"
          class="mt-2">
          <p class="font-bold">{{ $t('$General.Name') }}:</p>
          <p class="mt-1">{{ name }}</p>
        </div>

        <div
          v-if="address"
          class="mt-2">
          <p class="font-bold">{{ $t('$General.Address') }}:</p>
          <p class="mt-1">{{ address }}</p>
        </div>
      </div>

    </template>
    <feather-icon
      :icon="icon"
      :svgClasses="`h-4 w-4 text-${color} cursor-pointer`"/>
  </vx-tooltip>
</template>

<script>
import enums from '@/enums';

/**
 * Component to show the tax id verification result
 *
 * @module views/modules/user/user-account/user-account-settings/UserAccountSettingsDetailsTaxIdVerification.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} status - verification status
 * @vue-prop {string} description - description of tax ID
 * @vue-prop {string} name - verified tax ID name
 * @vue-prop {string} address - verified tax ID address
 * @vue-computed {string} icon - icon to show
 * @vue-computed {string} color - color to use
 * @vue-computed {string} title - title to show
 */
export default {
  name: 'UserAccountSettingsDetails',
  i18n: {
    messages: {
      en: {
        $TaxIdVerification: {
          PendingValidation: '{taxName} is pending validation',
          ValidRegister: 'Valid {taxName} registered to:',
          InvalidRegister: 'Invalid {taxName}',
          Unavailable: 'Validate is not supported to {taxName}',
        },
      },
    },
  },
  props: {
    status: {
      type: String,
      require: true,
      validator(val) {
        return Object.values(enums.StripeEnums.TaxId.VerificationStatus).includes(val);
      },
    },
    description: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: '',
    },
  },
  computed: {
    icon() {
      switch (this.status) {
        case this.$enums.StripeEnums.TaxId.VerificationStatus.PENDING:
          return 'ClockIcon';

        case this.$enums.StripeEnums.TaxId.VerificationStatus.VERIFIED:
          return 'CheckCircleIcon';

        case this.$enums.StripeEnums.TaxId.VerificationStatus.UNAVAILABLE:
          return 'AlertTriangleIcon';

        case this.$enums.StripeEnums.TaxId.VerificationStatus.UNVERIFIED:
          return 'XCircleIcon';

        default:
          return 'ClockIcon';
      }
    },
    color() {
      switch (this.status) {
        case this.$enums.StripeEnums.TaxId.VerificationStatus.VERIFIED:
          return 'success';

        case this.$enums.StripeEnums.TaxId.VerificationStatus.UNAVAILABLE:
          return 'warning';

        case this.$enums.StripeEnums.TaxId.VerificationStatus.UNVERIFIED:
          return 'danger';

        default:
          return '';
      }
    },
    title() {
      const prefix = '$TaxIdVerification';
      switch (this.status) {
        case this.$enums.StripeEnums.TaxId.VerificationStatus.PENDING:
          return this.$t(`${prefix}.PendingValidation`, {
            taxName: this.description,
          });

        case this.$enums.StripeEnums.TaxId.VerificationStatus.VERIFIED:
          return this.$t(`${prefix}.ValidRegister`, {
            taxName: this.description,
          });

        case this.$enums.StripeEnums.TaxId.VerificationStatus.UNAVAILABLE:
          return this.$t(`${prefix}.Unavailable`, {
            taxName: this.description,
          });

        case this.$enums.StripeEnums.TaxId.VerificationStatus.UNVERIFIED:
          return this.$t(`${prefix}.InvalidRegister`, {
            taxName: this.description,
          });

        default:
          return 'ClockIcon';
      }
    },
  },
};
</script>
